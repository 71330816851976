<template>
  <div id="app">
    <header
      class="fixed top-0 left-0 w-full h-16 flex items-center bg-white shadow-lg px-12"
    >
      <div class="w-64">
        <img class="w-full h-auto" src="./assets/sfcc-logo.svg" />
      </div>
    </header>
    <div class="fixed z-40" v-if="isLoading">
      <LoadingState />
    </div>
    <div>
      <router-view v-if="fetchCompleted" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  Actions as AppActions,
  Getters as AppGetters
} from '@/store/modules/app/types'
import LoadingState from '@/components/LoadingState/LoadingState.vue'

export default {
  components: {
    LoadingState
  },
  computed: {
    ...mapGetters('app', {
      isLoading: [AppGetters.IS_LOADING],
      fetchCompleted: [AppGetters.HAS_FETCHED],
      isError: [AppGetters.IS_ERROR]
    })
  },
  mounted() {
    this.$store.dispatch(`app/${AppActions.CLEAR_ERROR}`)
  }
}
</script>

<style lang="scss">
@import 'assets/styles/tailwind.postcss';

/* prettier-ignore */
@font-face {
  font-family: 'Gilroy';
  font-weight: 600;
  src:
    url('./assets/fonts/gilroy-medium.woff2') format('woff2'),
    url('./assets/fonts/gilroy-medium.woff') format('woff');
}

/* prettier-ignore */
@font-face {
  font-family: 'Gilroy';
  font-weight: 800;
  src:
    url('./assets/fonts/gilroy-bold.woff2') format('woff2'),
    url('./assets/fonts/gilroy-bold.woff') format('woff');
}

/* prettier-ignore */
@font-face {
  font-family: 'Vodka';
  font-weight: 800;
  src:
    url('./assets/fonts/highlight.woff2') format('woff2'),
    url('./assets/fonts/highlight.woff') format('woff');
}
</style>
