import axios from 'axios'

export const ApiSetup = {
  setBaseUrl(url) {
    axios.defaults.baseURL = url
  }
}

export const Endpoints = {
  GET: {
    RECIPES: `${process.env.VUE_APP_RECIPE_API_BASE}?fields=variants,id,title,categories,slug&limit=-1`
  }
}

export const ApiService = {
  get(resource, params) {
    return axios.get(resource, params)
  },
  post(resource, payload) {
    return axios.post(resource, payload)
  },
  getRecipes() {
    return axios.get(Endpoints.GET.RECIPES)
  }
}
